import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import Newsletter from "../components/Newsletter";

const ProductTemplate = ({ data: { item } }) => {
  return (
    <Layout>
      <div className="pt-32 md:pt-36">
        <Link className="" to="/store">
          <div className="flex mx-5 md:mx-10 lg:mx-12 -mb-3">
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <h1 className="pl-3 text-sm">BACK TO STORE</h1>
          </div>
        </Link>
        <section className="my-10 md:my-14 lg:my-20 xl:my-16">
          <div className="xl:mx-5">
            <div className="md:grid md:grid-cols-12 mx-5 md:mx-10 lg:mx-12">
              <div className="lg:col-span-7 xl:col-span-6 md:col-span-6 lg:mr-20 lg:gap-5 pb-10 lg:pb-0">
                <Img
                  fluid={item.image.fluid}
                  className="md:w-full md:h-auto w-full h-3/4 object-contain"
                />
                <div className="grid grid-cols-2 lg:gap-5 gap-2 pt-2 md:pt-5">
                  {item.moreImages.map(({ fluid: fluid }) => {
                    return (
                      <Img
                        fluid={fluid}
                        className="md:w-full md:h-56 object-contain"
                      />
                    );
                  })}
                </div>
              </div>
              <div className="lg:col-span-5 xl:col-span-6 md:col-span-6 col-span-12 md:ml-10 lg:ml-0">
                <div className="w-auto md:w-full px-0 md:px-0 lg:px-0 xl:px-0">
                  <h1 className="text-amber-800 text-4xl md:text-5xl lg:text-5xl xl:text-6xl font-bold">
                    {item.name}
                  </h1>
                  <h2 className="pt-2 pb-4 text-sm md:text-base lg:text-lg text-gray-500 font-medium uppercase tracking-wide">
                    {item.category}
                  </h2>
                  {item.bestSeller && (
                    <p className="text-xs w-32 lg:text-sm xl:text-sm text-center bg-green-500 text-white tracking-wide px-3 py-2">
                      Best Seller
                    </p>
                  )}
                  <h2
                    className={`pb-8 text-5xl font-bold ${
                      item.bestSeller ? "pt-10" : "pt-5"
                    }`}
                  >
                    ${item.price}
                  </h2>
                  <div
                    className="snipcart-add-item lg:w-3/4 text-center bg-black text-white cursor-pointer text-sm py-4 rounded-md hover:bg-white hover:text-black transition-all duration-300 ease-linear border-2"
                    data-item-id={item.id}
                    data-item-price={item.price}
                    data-item-url={`https://zadokmart.com/products/${item.productSlug}`}
                    data-item-image={item.image.fluid.src}
                    data-item-name={item.name}
                    data-item-custom1-name={
                      item.category === "Leather & Clothes" ? "Size" : ""
                    }
                    data-item-custom1-options={
                      item.category === "Leather & Clothes" ? "S|M|L|XL" : ""
                    }
                  >
                    ADD TO CART
                  </div>
                  <h1 className="md:pt-16 pt-12 text-xl font-semibold">
                    Product Description
                  </h1>
                  <p className="lg:pr-10 lg:pt-10 md:pt-6 pt-3 md:text-sm lg:text-base">
                    {item.description.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Newsletter />
    </Layout>
  );
};

// run template query
export const query = graphql`
  query productTemplateQuery($slug: String!) {
    item: contentfulAllProducts(productSlug: { eq: $slug }) {
      id
      name
      price
      category
      description {
        description
      }
      bestSeller
      discountCode
      productSlug
      image {
        fluid(maxHeight: 350, quality: 100) {
          src
          ...GatsbyContentfulFluid_withWebp
        }
      }
      moreImages {
        fluid(maxHeight: 250) {
          src
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;

export default ProductTemplate;
